import {
  initApp,
  packageVersion as rxnanoBasePackageversion,
  rxnanoComponent,
  triggerPageChange,
  preloadMaps,
  JSXFactory
} from '@appzuka/rxnano';

import { packageVersion as rxnanoContactformPackageversion } from '@appzuka/rxnano-contactform';
import { pageList } from './site/pages/pages';

const CommitInfo = require('virtual/commitinfo.js');

import { HeadMenuContent, DropMenuContent, SideMenuContent, HeaderSiteLogo,
    FooterContacts, FooterAddress, FooterSiteLogo, SiteData} from './site/siteContent';

import './site/site.scss';

const prerendering = window.location.search.match(/prerender/)?true:false;

let pageChangeObservable = initApp(pageList, SiteData);

// div already present in html
// only inserted once
// NICE: Could be delayed until after main render
if (!prerendering) {
  rxnanoComponent.insertFooter(FooterContacts, FooterAddress, FooterSiteLogo, [CommitInfo.server, CommitInfo.commit].join(' '), CommitInfo.builddate,
    ["bluesword/"+CommitInfo.commit, rxnanoBasePackageversion, rxnanoContactformPackageversion],
    { copyrightYear: '2024' });
}

const wide = document.body.clientWidth>1023;
const DM = wide ? rxnanoComponent.DropMenu(DropMenuContent) : rxnanoComponent.SideMenu(SideMenuContent); 

// NICE: Could set up drop menu after main render.  Would need to show hamburger icon during first render
rxnanoComponent.HeadMenu(pageChangeObservable, HeadMenuContent, HeaderSiteLogo, DM);
triggerPageChange(null);

if (!prerendering) {
  rxnanoComponent.ScrollMenu(pageChangeObservable, HeadMenuContent, HeaderSiteLogo, DM);  // Once installed, the scroll menu handles itself

  // Preload the maps api if required on the contact page
  preloadMaps(5000);
}

import './site/pages/overrides.scss'; // Overrides last
