import { JSXFactory, render } from '@appzuka/rxnano';
import './logo.scss';


export const BlueswordLogo = (logoClass:string) => {

  render(<div class={'bluesword-logo '+logoClass}>
    <h2>BLUE<span>SWORD</span></h2>
  </div>)

}

export const BlueswordLogo2 = (logoClass:string) => {

  render(<div class={'bluesword-logo2 '+logoClass}>
    <div class="logo-left">
      <h2>Blue</h2>
    </div>
    <div class="logo-mid">
      <img src='/sword.png' />
    </div>
    <div class="logo-right">
      <h2>Sword Construction Ltd.</h2>
      <div class="logo-tagline">
        <h3>CONCRETE FRAME CONTRACTORS</h3>
        <h3 class="logo-services">GROUNDWORKS - FORMWORK - REINFORCING - CONCRETE</h3>
      </div>
    </div>
  </div>)

}

const svginline = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 237">
<path d="M78,71.97h-29.55c.26,5.64.48,10.92.75,16.21.41,7.98.87,15.96,1.28,23.94.65,12.55,1.29,25.11,1.91,37.67.54,10.89,1.06,21.79,1.59,32.68.17,3.58.22,7.16.54,10.72.29,3.17-.42,6.03-1.62,8.93-4.41,10.65-8.7,21.35-13.04,32.02-.25.61-.55,1.19-1.02,2.2-.61-1.47-1.09-2.59-1.55-3.73-3.91-9.78-7.62-19.64-11.8-29.31-1.97-4.56-2.16-9.1-1.88-13.86.52-8.89.94-17.79,1.41-26.69.6-11.64,1.21-23.28,1.81-34.92.38-7.32.75-14.64,1.12-21.95.49-9.65.98-19.29,1.45-28.94.07-1.49.01-2.99.01-4.98H0c0-3,0-6,0-9,10.11-1.71,20.21-3.42,30.42-5.15.54-10.2.84-20.17,1.69-30.1.42-4.88-.46-9.32-2.04-13.82-2.02-5.75-1.91-5.79,3.18-9.51,1.81-1.32,3.65-3.64,5.47-3.63,1.94.01,3.91,2.18,5.78,3.52,5.38,3.85,5.33,3.87,3.33,10.11-.85,2.66-2.19,5.41-2.14,8.09.2,9.39.89,18.78,1.43,28.16.14,2.39.38,4.77.57,7.17,10.21,1.74,20.25,3.45,30.3,5.17v9ZM38.33,181.41c.38-.03.76-.05,1.14-.08.51-2.31,1.45-4.61,1.45-6.92.1-31.67.07-63.34.06-95,0-1.74.23-4.2-1.95-4.22-2.5-.03-2.02,2.55-2.02,4.3-.02,31.5-.04,63,.04,94.5,0,2.48.82,4.95,1.26,7.42ZM38.76,15.01c1.99-.14,3.02-1.27,3.04-3.02.03-1.69-1.07-2.86-2.77-2.89-1.73-.03-3.01.86-3.04,2.83-.03,1.91,1.12,2.78,2.76,3.09Z" stroke-width="0"/>
<path d="M38.33,181.41c-.44-2.47-1.26-4.95-1.26-7.42-.09-31.5-.06-63-.04-94.5,0-1.75-.48-4.33,2.02-4.3,2.18.03,1.95,2.48,1.95,4.22,0,31.67.03,63.34-.06,95,0,2.31-.95,4.61-1.45,6.92-.38.03-.76.05-1.14.08Z" fill="%23fff" stroke-width="0"/>
<path d="M38.76,15.01c-1.64-.31-2.79-1.18-2.76-3.09.03-1.96,1.31-2.85,3.04-2.83,1.7.02,2.79,1.2,2.77,2.89-.03,1.75-1.06,2.88-3.04,3.02Z" fill="%23fff" stroke-width="0"/></svg>`;

const SwordIcon = (color) => {
  render(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 237">
      <path d="M78,71.97h-29.55c.26,5.64.48,10.92.75,16.21.41,7.98.87,15.96,1.28,23.94.65,12.55,1.29,25.11,1.91,37.67.54,10.89,1.06,21.79,1.59,32.68.17,3.58.22,7.16.54,10.72.29,3.17-.42,6.03-1.62,8.93-4.41,10.65-8.7,21.35-13.04,32.02-.25.61-.55,1.19-1.02,2.2-.61-1.47-1.09-2.59-1.55-3.73-3.91-9.78-7.62-19.64-11.8-29.31-1.97-4.56-2.16-9.1-1.88-13.86.52-8.89.94-17.79,1.41-26.69.6-11.64,1.21-23.28,1.81-34.92.38-7.32.75-14.64,1.12-21.95.49-9.65.98-19.29,1.45-28.94.07-1.49.01-2.99.01-4.98H0c0-3,0-6,0-9,10.11-1.71,20.21-3.42,30.42-5.15.54-10.2.84-20.17,1.69-30.1.42-4.88-.46-9.32-2.04-13.82-2.02-5.75-1.91-5.79,3.18-9.51,1.81-1.32,3.65-3.64,5.47-3.63,1.94.01,3.91,2.18,5.78,3.52,5.38,3.85,5.33,3.87,3.33,10.11-.85,2.66-2.19,5.41-2.14,8.09.2,9.39.89,18.78,1.43,28.16.14,2.39.38,4.77.57,7.17,10.21,1.74,20.25,3.45,30.3,5.17v9ZM38.33,181.41c.38-.03.76-.05,1.14-.08.51-2.31,1.45-4.61,1.45-6.92.1-31.67.07-63.34.06-95,0-1.74.23-4.2-1.95-4.22-2.5-.03-2.02,2.55-2.02,4.3-.02,31.5-.04,63,.04,94.5,0,2.48.82,4.95,1.26,7.42ZM38.76,15.01c1.99-.14,3.02-1.27,3.04-3.02.03-1.69-1.07-2.86-2.77-2.89-1.73-.03-3.01.86-3.04,2.83-.03,1.91,1.12,2.78,2.76,3.09Z" stroke-width="0"/>
      <path d="M38.33,181.41c-.44-2.47-1.26-4.95-1.26-7.42-.09-31.5-.06-63-.04-94.5,0-1.75-.48-4.33,2.02-4.3,2.18.03,1.95,2.48,1.95,4.22,0,31.67.03,63.34-.06,95,0,2.31-.95,4.61-1.45,6.92-.38.03-.76.05-1.14.08Z" stroke-width="0"/>
      <path d="M38.76,15.01c-1.64-.31-2.79-1.18-2.76-3.09.03-1.96,1.31-2.85,3.04-2.83,1.7.02,2.79,1.2,2.77,2.89-.03,1.75-1.06,2.88-3.04,3.02Z" stroke-width="0"/>
    </svg>
  );

}

export const BlueswordLogoSmall = (logoClass:string) => {

  render(<div class={'bluesword-logo-small '+logoClass}>
    <div class="logo-text">
      <div class="logo-left">
        <h2>Blue</h2>
      </div>
      <div class="logo-mid">
        {/* <img src={svginline} /> */}
        { () => SwordIcon('red') }
      </div>
      <div class="logo-right">
        <h2>Sword</h2>
      </div>
    </div>
    <h3>CONCRETE FRAME CONTRACTORS</h3>
  </div>)

}


