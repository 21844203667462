import { JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, patch, render } from '@appzuka/rxnano';
import { first, map, takeWhile } from 'rxjs/operators';

const CommitInfo = require('virtual/commitinfo.js');

// FIXME: Force loaded pixlist if it is not loaded at start

import './accreditations.scss';

let accredEl;

const accreditations = () => {
  render(<>
      <h2>Accreditations</h2>
      <p>Blue Sword are proud to be members of industry associations to ensure the quality of our services:</p>
      <div ref={(e) => { accredEl = e; return e }} id="accreditations-wrapper" />
    </>);
  // When the pixList is loaded, fill the div.
  pixLoadedObservable
    .pipe(
      first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
      map(pl => pl.filter(p => p.file.match(/^accreditations/))), // Filter for the chosen image
    )
    .subscribe(pix => {
      patch(accredEl,
        () => pix.map(p => { sitePix(exportedSiteData.assetBase, p.file) })
      );
    });
}

let clientEl;

const clients = () => {
  render(<>
    <h2>Clients</h2>
    <p>At Blue Sword we are pleased to have leading organisations in the construction industry as clients.  Our clients include:</p>
    <div ref={(e) => { clientEl = e; return e }} id="accreditations-wrapper" />
  </>);
  // When the pixList is loaded, fill the div.
  pixLoadedObservable
    .pipe(
      first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
      // TODO: Can remove allen filter once removed from pixlist
      map(pl => pl.filter(p => p.file.match(/^logos/)).filter(p => !p.file.match(/allen/))), // Filter for the chosen image
    )
    .subscribe(pix => {
      patch(clientEl,
        () => pix.map(p => { sitePix(exportedSiteData.assetBase, p.file) })
      );
    });
}

export {
  accreditations,
  clients
};

