import { PageContent, completeCallback, sitePix, exportedSiteData, render, JSXFactory } from '@appzuka/rxnano';
import { accreditations, clients } from 'site/components/accreditations';

import './services.scss';

const Services = () => {
  render(<div>
    <h2>Blue Sword Services</h2>
    <p>We at Blue Sword have been running as a family Business since 1996 supplying a high standard of groundworks, formwork, reinforcing and concrete.</p>

    <div class="service-outer-wrapper"> 
      <h2 id='groundworks'>Groundworks</h2>
      <div class="service-wrapper">
        { () => sitePix(exportedSiteData.assetBase, 'services/groundwork') }
        <div>
          <p>Blue Sword cover all aspects of groundworks and are proud to offer the resources, expertise and experience to consistently work to a high standard.</p>
        </div>
      </div>
    </div>

    <div class="service-outer-wrapper"> 
      <h2 id='formwork'>Formwork</h2>
      <div class="service-wrapper">
        { () => sitePix(exportedSiteData.assetBase, 'services/formwork') }
        <div>
          <p>At Blue Sword we offer a one stop shop in providing all materials and equipment. We work with our clients to understand their unique needs and deliver solutions that exceed their expectations.</p>
        </div>
      </div>
    </div>

    <div class="service-outer-wrapper"> 
      <h2 id='reinforcing'>Reinforcing</h2>
      <div class="service-wrapper">
        { () => sitePix(exportedSiteData.assetBase, 'services/reinforcing') }
        <div>
          <p>Reinforced concrete has steel is embedded so that the two materials combine to resist forces. Offering improved strength against the force of wind, vibrations etc. reinforced concrete delivers the combined tensile strength of steel and compressive strength of concrete.</p>
        </div>
      </div>
    </div>

    <div class="service-outer-wrapper"> 
      <h2 id='concrete'>Concrete</h2>
      <div class="service-wrapper">
        { () => sitePix(exportedSiteData.assetBase, 'services/concrete') }
        <div>
          <p>Blue Sword are a specialist reinforced concrete contractor with over 25 years' experience in the delivery of complex concrete structures.</p>
        </div>
      </div>
    </div>
  </div>);

}


const servicePages: PageContent = {
  content: [
    {
      pageLocation: 'services',
      image: { imageName: 'banners/quantock', title: 'Services' },
      pageTitle: 'Services',
      content: () => {
        Services();
        completeCallback('services');
      },
      name: 'services'
    }
  ],
  subMenu: []

}

export {
  servicePages
};

