import { PageContent, completeCallback, sitePix, exportedSiteData, render, JSXFactory } from '@appzuka/rxnano';
import { accreditations, clients } from 'site/components/accreditations';

import './about.scss';

const AboutBluesword = () => {
  render(<div class='about-bluesword'>
    <h2>About Blue Sword</h2>
    <p>We at Blue Sword have been running as a family Business since 1996 supplying a high standard of concrete frame, ground works and Drainage.  We pride ourselves on our ability to complete projects on time and within budget. We have a proactive approach to health & safety and believe in the advantage of a tidy and efficient work ethic. This philosophy enables us to meet tight programs and provide competitive rates.</p>
    { clients }
    { accreditations }
  </div>);

}


const aboutPages: PageContent = {
  content: [
    {
      pageLocation: 'about',
      image: { imageName: 'banners/quantock', title: 'About' },
      pageTitle: 'About',
      content: () => {
        AboutBluesword();
        completeCallback('about');
      },
      name: 'about'
    }
  ],
  subMenu: []

}

export {
  aboutPages
};

