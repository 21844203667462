import { PageContent, completeCallback, sitePix, exportedSiteData, render, patch, pixLoadedObservable, JSXFactory } from '@appzuka/rxnano';
import { first, map, takeWhile } from 'rxjs/operators';
import { GallerySet } from 'site/components/gallery';

import './projects.scss';


const Projects = () => {
  render(<div>
    <h2>Quantock House</h2>
    <p>The £1.31 million Quantock House project delivered over 38 weeks for affordable residential client Affordable Housing & Healthcare Group involved constructing an inviting, modern high-rise apartment complex in the heart of Taunton city catering to contemporary urban lifestyles.</p>
    <div class='projects-image-wrapper'>
      { () => GallerySet('projects', '')}
    </div>

  </div>);

}


const projectPages: PageContent = {
  content: [
    {
      pageLocation: 'projects',
      image: { imageName: 'banners/quantock', title: 'Projects' },
      pageTitle: 'Projects',
      content: () => {
        Projects();
        completeCallback('projects');
      },
      name: 'projects'
    }
  ],
  subMenu: []

}

export {
  projectPages
};

