import {
    elementOpen,
    elementVoid,
    elementClose,
    text
} from 'incremental-dom';

import { ContactType, rxnanoIcons, linkClick, JSXFactory, render } from '@appzuka/rxnano';
import { BlueswordLogo, BlueswordLogoSmall } from 'site/components/logo';

const CommitInfo = require('virtual/commitinfo.js');

import { ISiteData } from "rxnanoModels";

interface FooterContactLine {
    type: ContactType,
    icon: any,
    label: string;
    target: string
}

type FooterContactArray  = FooterContactLine[];

export const MapsAPIKey = 'AIzaSyAWGm7lJKidOYOZwusjGXLXgJApnXf5XQ8';

export const FooterContacts : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.worldIcon, label: 'www.bluesword-construction.com', target: '/'},
    {type: ContactType.Mail, icon: rxnanoIcons.emailIcon, label: 'office@blueswordconstruction.com', target: 'office@blueswordconstruction.com'},
    {type: ContactType.Line, icon: rxnanoIcons.phoneIcon, label: '+44 (0) 118 9811869', target: ''},
];

export const FooterAddress : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.locationIcon, label: 'Unit 7, Brick Kiln Industrial Estate', target: '/contact'},
    { type: ContactType.Line, icon: rxnanoIcons.locationIcon, label: 'Silchester Rd, Tadley', target: '' },
    { type: ContactType.Line, icon: rxnanoIcons.locationIcon, label: 'RG26 3PX, UK', target: '' },
    {type: ContactType.Link, icon: rxnanoIcons.mailIcon, label: 'Contact Us', target: '/contact'}
];

export const HeaderSiteLogo = () => {
  render(<div class='full-logo'>
    { () => BlueswordLogoSmall('logo-small') }
  </div>);
}

export const FooterSiteLogo = () => {
    render(<div class='full-logo'>
        { () => BlueswordLogoSmall('logo-small') }
        <ul>
            <li>GROUNDWORKS</li>
            <li>FORMWORK</li>
            <li>REINFORCING</li>
            <li>CONCRETE</li>
        </ul>
      </div>);

}

interface HeadMenuItem {
    menuText: string
    link: string
    priority: number
}

type HeadMenuArray = HeadMenuItem[];
type DropMenuArray = HeadMenuArray[];

const defineMenuItem = (item:string, priority: number, link?:string) : HeadMenuItem  =>
  ({menuText: item, priority: priority, link: link===undefined?'/'+item.toLowerCase():link});

export const PageLabels = {
    'about': 'about us'
};

export const HeadMenuContent : HeadMenuArray = [
    defineMenuItem('About', 4),
    defineMenuItem('Services', 6),
    defineMenuItem('Contact', 8)
];

export const TopMenuContent = HeadMenuContent;

const DropMenuContentArray: DropMenuArray = [
    [
        defineMenuItem('About Us', 1, '/about'),
        defineMenuItem('Home', 1, '/'),
        defineMenuItem('Contact', 1),
        defineMenuItem('Privacy', 1, '/policies/privacy'),
        defineMenuItem('Terms', 1, '/policies/terms')
    ],
    [
        defineMenuItem('Services', 1),
        defineMenuItem('Groundworks', 1,  '/services#groundworks'),
        defineMenuItem('Formwork', 1,  '/services#formwork'),
        defineMenuItem('Reinforcing', 1,  '/services#reinforcing'),
        defineMenuItem('Concrete', 1,  '/services#concrete'),
    ],
    [
        defineMenuItem('Portfolio', 1, '/projects'),
        defineMenuItem('Projects', 1, '/projects'),
    ]
];

type vf = (string?) => void;

export const DropMenuContent: DropMenuArray | vf = (currentLocation?: string) => {
    DropMenuContentArray.map((c, j) => {
        elementOpen('div', null, null, 'class', 'hovermenucolumn');
        elementOpen('ul');
            c.map(({menuText, link}, i) => {
                let classList, finalMenuText;
                if (menuText[0] === '*') {
                    classList = 'other-link';
                    finalMenuText = menuText.slice(1);
                } else {
                    classList = currentLocation===link?'active-link':'other-link';
                    finalMenuText = menuText;
                }
                elementOpen('li');
                elementOpen('a', `hmc-${link}-${i}`, null, 'href', link,
                'class', classList,
                'onclick', (event) => {
                    linkClick(event, link);
                });
                if (finalMenuText.length === 0) {
                    text(' ');
                } else {
                    text(finalMenuText);
                }

                elementClose('a');
                elementClose('li');
            });
        elementClose('ul');
    elementClose('div');
    });
}

type SideMenuArray = Array<Array<Array<string>>>;

const SideMenuContentArray: SideMenuArray = [
    [
        ['About Us', '/about'],
        ['Home', '/'],
        ['Contact', '/contact'],
        ['Privacy', '/policies/privacy'],
        ['Terms', '/policies/terms']
    ],
    [
        ['Services', '/services'],
        ['Groundworks', '/services#groundworks'],
        ['Formwork', '/services#formwork'],
        ['Reinforcing', '/services#reinforcing'],
        ['Concrete', '/services#concrete'],
    ],
    [
        ['Portfolio', '/projects'],
        ['Projects', '/projects'],

    ]
];

// TODO: If the link us is the same the menu does not close
export const SideMenuContent: SideMenuArray | vf = (currentLocation?: string) => {
    SideMenuContentArray.map((menuColumn, j) => {
        elementOpen('div', null, null, 'class', 'sidemenucolumn');
        elementOpen('ul');
        menuColumn.map(([menuText, link], i) => {
                elementOpen('li');
                elementOpen('a', `smc-${link}-${i}`, null, 'href', link,
                'class', ((currentLocation===link) && ((j===0) || (i===0)))?'active-link':'other-link',
                'onclick', (event) => {
                    linkClick(event, link);
                });
                    text(menuText);
                elementClose('a');
                elementClose('li');
            });
        elementClose('ul');
    elementClose('div');
    });
}

interface ContactPageItem {
    icon: any,
    label: string,
    linkText: string,
    link?: string
}

type ContactPageArray = ContactPageItem[];

const defineContactPageItem = (icon: any, label:string, linkText:string, link?:string) : ContactPageItem  =>
  ({icon: icon, label: label, linkText: linkText, link: link});


const siteAddress = {
    mapinfo: {
        latitude: 51.355733672926156,
        longitude: -1.1389780978905188,
        zoom: 8,
        title: 'BlueSword'
    },
    details: [
        defineContactPageItem(rxnanoIcons.locationIcon, 'Address', 'Unit 7, Brick Kiln Industrial Estate, Silchester Rd, Tadley, RG26 3PX, UK'),
        defineContactPageItem(rxnanoIcons.phoneIcon, 'Phone', '+44 (0) 118 9811869'),
        defineContactPageItem(rxnanoIcons.worldIcon, 'Web', 'www.bluesword-construction.com', '/'),
        defineContactPageItem(rxnanoIcons.emailIcon, 'eMail', 'office@blueswordconstruction.com', 'mailto:office@blueswordconstruction.com'),
    ]
}

export const ContactAddressList = [
    siteAddress
]

export const SiteData:ISiteData = {
    ga: window.location.hostname==='www.bluesword-construction.com'?'UA-47087919-xx':null,
    pageTitleBase: 'BlueSword',
    assetBase: 'https://appzuka-web.s3.eu-west-2.amazonaws.com/bluesword/imagestream/',
    fullpixlist: 'fullpixlist.json',
    index: CommitInfo.index,
    siteName: 'www.bluesword-construction.com',
    highlight: '#68b0e0',
    pageLabels: {
        '/about': 'about us'
    },
    cognitoConfig: {
        region: 'eu-west-1',
        clientID: '28toug1d6udh9ipmk1cbejd07q',
        userpool: 'eu-west-1_ktnvCHUat',
        identityPool: 'eu-west-1:72ed9f40-bf99-4692-960d-818c47b16351'
    }
}

export const PrivacyContacts = {
    company: 'Bluesword',
    policyDate: 'May 2024',
    contact: 'office@blueswordconstruction.com'
}

// export const assetBase = 'https://appzuka-web.s3.eu-west-2.amazonaws.com/appzuka/content/';



