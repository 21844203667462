import {
    patch,
    elementOpen,
    elementClose,
    text
} from 'incremental-dom';

import { PageListItem, JSXFactory, termsPages, linkClick, render } from '@appzuka/rxnano';

import { aboutPages } from 'site/pages/about/aboutPages';
import { homePages } from 'site/pages/home/home';
import { servicePages } from 'site/pages/services/servicePages';
import { projectPages } from 'site/pages/projects/projectPages';
import { notFoundPage } from '@appzuka/rxnano';
import { contactPage } from 'site/pages/contact';
import { contactForm } from '@appzuka/rxnano-contactform';

// import { contactPage } from 'pages/contact';

import { SiteData, ContactAddressList, PrivacyContacts, DropMenuContent as dropmenu } from 'site/siteContent';

const CommitInfo = require('virtual/commitinfo.js');

// FIXME: Fix for narrow screens
const siteLinks = () => {
    elementOpen('div', 'hmc', null, 'class', 'hovermenucontent');
    if (typeof dropmenu === 'function') {
        dropmenu(window.location.pathname);
    } else {
        dropmenu.map((c, j) => {
            elementOpen('div', null, null, 'class', 'hovermenucolumn');
            elementOpen('ul');
                c.map(({menuText, link}, i) => {
                    let classList, finalMenuText;
                    if (menuText[0] === '*') {
                        classList = ['class', 'drop-menu-spacer'];
                        finalMenuText = menuText.slice(1);
                    } else {
                        classList = null;
                        finalMenuText = menuText;
                    }
                    elementOpen('li', null, classList);
                    elementOpen('a', `hmc-${link}-${i}`, null, 'href', link,
                    'class', window.location.pathname === link?'active-link':'other-link',
                    'onclick', (event) => {
                        linkClick(event, link);
                    });
                        text(finalMenuText);
                    elementClose('a');
                    elementClose('li');
                });
            elementClose('ul');
        elementClose('div');
        });
    }
    elementClose('div');
};

// Copy submenu into each page
const pageData = (pages) => {
    return(pages.content.map(k => {return {...k, subMenuClass: pages.subMenuClass, subMenu: pages.subMenu}}))
};

// Pass in an empty function to omit the form
const siteContactForm = (pco) => contactForm(pco, {
    testMode: !CommitInfo.production || CommitInfo.test,
    sourceSite: SiteData.siteName,
    highlight: SiteData.highlight,
    commitVersion: CommitInfo.commit
    });

const pageList : PageListItem[] = [
    ...pageData(notFoundPage('banners/notfound', siteLinks)),
    ...pageData(contactPage([() => {render(<h2>Contact</h2>)}, ...ContactAddressList], siteContactForm)),
    ...pageData(homePages),
    ...pageData(aboutPages),
    ...pageData(servicePages),
    ...pageData(projectPages),
    ...pageData(termsPages(PrivacyContacts)),
];

export {
    pageList
};
  
