import { first } from 'rxjs/operators';
import { JSXFactory, mapLoadedObservable, render, sitePix,exportedSiteData, linkClick,} from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';
import { PageContent, completeCallback } from '@appzuka/rxnano';

import './contact.scss';

const loadMap = (index, mapinfo) => {

    mapLoadedObservable
    .pipe(
        first(v => v === true)
    )
    .subscribe(() => {
        const myLatlng = new google.maps.LatLng(mapinfo.latitude, mapinfo.longitude);
        const mapOptions = {
            zoom: mapinfo.zoom,
            center: myLatlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            // styles: [
            //     {featureType: <google.maps.MapTypeStyleFeatureType>"all", stylers: [{visibility: 'off'}]},
            //     {featureType: <google.maps.MapTypeStyleFeatureType>"landscape.natural", stylers: [{visibility: 'off'}]},
            //     {featureType: <google.maps.MapTypeStyleFeatureType>"road.highway", stylers: [{visibility: 'off'}]},
            //     {featureType: <google.maps.MapTypeStyleFeatureType>"water", stylers: [{visibility: 'on'}]},
            //     {elementType: <google.maps.MapTypeStyleElementType>"labels.text", stylers: [{visibility: 'off'}]},
            //   ]
        };
        const mapElement = document.getElementById(`contact-map-${index}`);
        if (mapElement !== null) {
            const map = new google.maps.Map(mapElement, mapOptions);
            new google.maps.Marker({
                position: myLatlng,
                map: map,
                title: mapinfo.title
            });
        }   
    });
};

const contactPageContent = (pageChangeObservable, ContactAddressList, contactForm) => {

    <render>
        <div class='contact-wrapper margin-container'>
            {() => ContactAddressList.map((item, i) => {
                typeof item === 'function' ?
                <render><div class='contact-page-row'>{item}</div></render>
                : <render>
                    <div class='contact-address-row'>
                        <div class='contact-details'>
                            {item.title?<h2>{item.title}</h2>:''}
                            {() => item.details.map(rowItem => {
                                typeof rowItem === 'function' ?
                                <render>{rowItem}</render>
                                : <render>
                                    <div class='contact-address contact-row'>
                                    {() => rowItem.icon('#333')}
                                    <p class='contact-label'>{rowItem.label}</p>
                                    <p class='contact-row-content'>{
                                        rowItem.link
                                        ? <fragment>
                                            <a class='contact-row-content-link' href={rowItem.link} target='_blank' rel='noopener noreferrer'>{rowItem.linkText}</a>
                                        </fragment>
                                        : rowItem.linkText
                                    }</p>
                                    </div>
                                </render>

                            })}
                        </div>
                        {item.mapinfo?<div id={`contact-map-${i}`} class='contact-map'></div>:''}
                    </div>
                </render>
                // loadMap(i, item.mapinfo);
            })}
        </div>
        <div class='margin-container'>
          <h2>Contact Form</h2>
          <p>Please use the form below to contact Blue Sword.</p>
          {() => contactForm(pageChangeObservable)}
        </div>

    </render>

    // NICE: Preload a low res image of the map

    // if (MapInfo) loadMap(MapInfo);
    ContactAddressList.map((item, i) => {
        if ((typeof item !== 'function') && (item.mapinfo !== null)) {
            loadMap(i, item.mapinfo);
        }
    });

};

const contactPage = (ContactAddressList, contactForm) => {

    return({
        content: [
            {
                pageLocation: 'contact',
                image: {imageName: 'banners/contact', title: 'Contact Us'},
                pageTitle: 'Contact',
                name: 'contact',
                content: (pco) => contactPageContent(pco, ContactAddressList, contactForm)
            }
        ],
        subMenu: []
    });
};

export {
    contactPage,
    // loadMap,
};

