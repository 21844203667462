import { JSXFactory, render, sitePix,exportedSiteData, linkClick  } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';
import { PageContent, completeCallback } from '@appzuka/rxnano';
import { BlueswordLogo2 } from 'site/components/logo';
import { accreditations, clients } from 'site/components/accreditations';
import { statementBanner } from 'site/components/statementBanner';

import './home.scss';
import topSVG from './tophex.svg';


const About = () => {
  render(
    <div class="about-home margin-container">
      <h2>About BlueSword</h2>
      <p>We at Blue Sword have been running as a family Business since 1996 supplying a high standard of concrete frame, ground works and Drainage.  We pride ourselves on our ability to complete projects on time and within budget. We have a proactive approach to health & safety and believe in the advantage of a tidy and efficient work ethic. This philosophy enables us to meet tight programs and provide competitive rates.</p>
      <p>Based between Reading and Basingstoke, we work all over London and the south of England. Blue Sword have completed projects for our clients in many areas, including Oxford, Swindon, Reading, Bath, Southampton, Portsmouth and across Hampshire, Wiltshire, Dorset, Somerset, Surrey, West Susse, Berkshire, Oxfordshirt and Buckinghamshire.</p>
    </div>
  )
}

const Services = () => {
  render(
    <div class="services-home margin-container">
      <h2>Our Services</h2>
      <p>We have over 50 years' experience providing services to the construction industry including:</p>
      <ul>
        <li>Concrete Framework</li>
        <li>Groundworks</li>
        <li>Drainage</li>
      </ul>
    </div>
  )
}

const Sectors = () => {
  render(
    <div class="sectors-home margin-container">
      <h2>Sectors</h2>
      <p>Blue Sword have expertise across a wide range of industry sectors</p>
      <ul>
      <li>Residential</li>
      <li>Education</li>
      <li>Leisure</li>
      <li>Retail</li>
      <li>Commercial</li>
      <li>Industrial</li>
      </ul>
    </div>
  )
}


const ShowCase = () => {
  render(<a href='/projects' onclick={e => linkClick(e, '/projects')} class="recent-projects margin-container">
<h2>Recent Projects</h2>
<div class='recent-projects__wrapper'>
{ () => sitePix(exportedSiteData.assetBase, 'projects/quantock1', {mediaSizes: '20vw'}) }
<div class='recent-projects__content'>
  <h2>Quantock house</h2>
  <p>The £1.31 million Quantock House project delivered over 38 weeks for affordable residential client Affordable Housing & Healthcare Group involved constructing an inviting, modern high-rise apartment complex in the heart of Taunton city catering to contemporary urban lifestyles. </p>
  <p>Click here to see further details</p>
</div>
</div>
  </a>)
}

const wrapMargin = (block) => {
  render(<div class='margin-container'>
    { block }
  </div>)

}
const Logo = () => {
  render(
    <div class='hero-wrapper'>

      {/* <div class='hero-mask'></div> */}
      <div class='logo-container'>
        { BlueswordLogo2 }
      </div>
    </div>
  )

}

const hero = {
  imageName: 'banners/quantock',
  title: Logo
}


const homePages : PageContent = {
    content: [
        {
            pageLocation: '',
            image: hero,
            pageTitle: 'Home',
            content: () => {
              // BlueswordLogo2('logo-hero');
              About();
              Services();
              Sectors();
              statementBanner("Your trusted construction partner across the South West", "darkblue");
              ShowCase();
              statementBanner("Call Blue Sword now to discuss your project", "lightblue");
              wrapMargin(accreditations);
              wrapMargin(clients);

              completeCallback('home');
            },
            name: 'home'
        }
    ],
    subMenu: []
};

export {
    homePages
};

