import { JSXFactory, render } from '@appzuka/rxnano';

import './statementBanner.scss';

export const statementBanner = (text, colour) => {
    render(
    <div class={`statement-banner banner-${colour} margin-container`}>
      <div>
        <h2>{text}</h2>
      </div>
    </div>
    )
  }

